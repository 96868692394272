:root {
  --primary-color: #ffd700;
  --text-color: #333;
  --title-color: #fff;
  --black-color: #000000;
  --dark-color: #202121;
  --shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  --borderRadius: 20px;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  font-display: swap;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background: repeating-linear-gradient(
    90deg,
    #fcf1b2,
    #fcf1b2 1px,
    transparent 1px,
    transparent 380px
  );
  background-position: center;
}

@media (max-width: 900px) {
  body {
    background: repeating-linear-gradient(
      90deg,
      #fcf1b2,
      #fcf1b2 1px,
      transparent 1px,
      transparent 140px
    );
  }
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #000;
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

::selection {
  background: var(--primary-color);
  color: #000;
}

.btn-links {
  background: var(--primary-color);
  color: #1c1e22;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 30px;
  transition: .5s;
}

.App {
  animation: transitionIn 2s forwards;
  overflow-x: hidden;
}

@keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-go {
  position: relative;
  margin: 20px 0;
  width: max-content;
  padding: 15px 50px;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  border-radius: 40px;
  text-transform: uppercase;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  transition: all 0.3s ease-in;
}

.btn-go.dark {
  color: #000;
}

.btn-go:hover,
.btn-links:hover {
  background-color: transparent;
  color: #000;
  transform: scale(1.02);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 40px;
  margin-bottom: 0;
  font-weight: 700;
  text-align: start;
  text-transform: uppercase;
}

.sub-title {
  font-size: 32px;
  font-weight: 700;
  text-align: start;
  margin-bottom: 0;
  line-height: 45px;
}

.title b {
  color: red;
}

.kMuJsI {
  padding: 0;
}

.react-multiple-carousel__arrow {
  background: transparent;
}

@media (max-width: 1200px) {
  p {
    font-size: 18px;
  }

  .btn-go {
    justify-content: center;
    width: 100%;
  }
}

.text-width {
  width: 700px;
}

@media (max-width: 800px) {
  .title {
    font-size: 40px;
  }

  .sub-title {
    font-size: 27px;
  }
  .text-width {
    width: 100%;
  }
}

.info-chat {
  width: 60px;
  height: 60px;
  background: var(--dark-color);
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}

.chat-window {
  position: absolute;
  top: -400px;
  line-height: 40px;
  right: 0;
  width: 300px;
  border-radius: 20px;
  background: var(--dark-color);
  padding: 20px 20px 100px 20px;
  z-index: -1;
  animation: chat 1s;
}

.chat-window span {
  font-size: 18px;
}

.chat-window p {
  font-size: 13px;
  line-height: 15px;
}

.chat-window .form-chat {
  color: #fff;
  font-size: 16px;
  border: 1px solid var(--primary-color);
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.icon-box {
  display: flex;
  gap: 10px;
}

.info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  color: #fff;
  transition: .5s;
}

.info-icon:hover {
  background: var(--primary-color);
  color: var(--dark-color);
}

@keyframes chat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
