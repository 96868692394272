.testimonial-slider {
  border-radius: var(--borderRadius);
  margin: 20px 10px 0 0;
}

.testimonial-slider p {
  font-size: 18px;
  margin-top: 20px;
  font-style: italic;
  line-height: 1.7;
  color: var(--text-color);
}
.title-testi {
  display: flex;
  flex-direction: column;
}

.testimonial-slider h4 {
  font-size: 18px;
  margin: 10px 0 5px 0 !important;
  font-weight: 400;
  position: relative;
  text-transform: uppercase;
}

.react-multiple-carousel__arrow--left {
  display: none;
}

.react-multiple-carousel__arrow--right {
  display: none;
}

.react-multi-carousel-track {
  margin: 25px 0 !important;
}

@media (max-width: 768px) {
  .testimonial-slider p {
    font-size: 16px;
  }
}

@media (max-width: 430px) {
  .title-testi {
    display: block;
    text-align: -webkit-center;
  }
}

.img-testi {
  width: 60px;
  height: auto;
  overflow: hidden;
  background-position: top;
}

.img-testi img {
  width: 100%;
}
