.about-bg {
  position: relative;
  margin: 42px 0;
  border-radius: 10px;

  .about-heder {
    width: 100%;
    border-radius: 20px;
  }
}

.vision {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    margin-top: 50px;

    @media (max-width: 770px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item-text {
      width: 50%;

      @media (max-width: 770px) {
        width: 100%;
      }
      p {
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 1000px) {
          font-size: 16px;
        }
      }

      ol {
        color: var(--text-color);

        li {
          margin-bottom: 10px;
        }

        li::marker {
          color: var(--primary-color);
          font-size: 20px;
        }
      }
    }

    .item-img {
      width: 40%;

      @media (max-width: 770px) {
        width: 100%;
      }

      img {
        width: 100%;
      }

      h3 {
        color: var(--title-color);
      }
    }
  }

  .ribbon-container {
    position: relative;
    padding: 100px 0 0 0;

    .ribbon {
      width: 100%;
      position: relative;
      margin-top: 50px;
      color: #000;
      border-radius: 30px;
      border: 2px solid #eee;
      transition: .5s;

      &:hover {
        border: 2px solid var(--primary-color);
      }

      .ribbon-text {
        padding: 50px 100px;
        display: flex;
        align-items: center;
        gap: 200px;
        justify-content: space-between;

        @media (max-width: 900px) {
          gap: 50px;
          padding: 30px;
        }

        @media (max-width: 640px) {
          display: block;
        }

        .number {
          font-size: 70px;
          font-weight: bold;
          color: var(--primary-color);
        }

        .text {
          width: 100%;
        }
      }

      h3 {
        color: #000;
        font-size: 35px;
        font-weight: bold !important;
        margin: 0;
      }

      p {
        color: var(--text-color);
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}
