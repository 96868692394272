.about h1 {
  font-size: 74px;
  color: #000;
  font-weight: 900;
  letter-spacing: -2px;
}

.about p {
  color: var(--text-color);
  width: 700px;
  font-size: 20px;
}
.about a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.img-about div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40% !important;
  padding: 0 50px;
  height: 100%;
  float: right;
}

.img-about div h3 {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  text-align: start !important;
}

.img-about div p {
  color: var(--text-color);
}

@media (max-width: 900px) {
  .img-about div {
    width: 100% !important;
  }
  .about h1 {
    font-size: 35px;
  }

  .about p {
    font-size: 18px;
  }
}

@media (max-width: 770px) {
  .about p {
    width: 100%;
  }
}

.clients div {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.clients div div {
  border: 1px solid #eee;
  padding: 50px 20px;
}

.clients p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.clients img {
  width: 200px;
  height: 50px;
  object-fit: contain;
  filter: grayscale(1);
  transition: 0.5s;
}

.clients div div:hover img {
  filter: grayscale(0);
}

@media (max-width: 800px) {
  .clients img {
    width: 110px;
  }

  .clients div {
    justify-content: space-between;
  }
  .clients div div {
    width: 40%;
  }
}

/* Vision */

/* Client Home */

.clients-section {
  width: 100%;
  overflow: hidden;
  margin: 100px 0 0 0;
}

.clients-container {
  display: flex;
  animation: moveLeft 30s linear infinite;
  width: max-content;
}

.client-logo {
  margin-right: 50px; /* Razmak između logotipa */
  display: flex;
  width: 130px;
  justify-content: center;
  align-items: center;
}

.client-logo img {
  width: 100%; /* Prilagodi veličinu logotipa */
  height: 80px;
  object-fit: contain;
  filter: grayscale(1);
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -50%
    ); /* Move left by half of the container's total width */
  }
}
