.title-home {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  margin: 50px 0;
  gap: 100px;
}

.title-home img {
  width: 100%;
}

.btns {
  display: flex;
  gap: 20px;
  width: 100%;
}

.title-home h1 {
  font-size: 40px;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 900;
}
.title-home p {
  font-weight: 400;
  font-size: 18px;
  color: var(--text-color);
}

@media (max-width: 670px) {
  .title-home h1 {
    font-size: 35px;
  }
  .btns {
    flex-direction: column;
    margin-top: 20px;
  }
  .btns a {
    margin: 0;
  }
}

@media (max-width: 440px) {
  .title-home h1 {
    font-size: 30px;
  }

  .title-home {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin: 40px 0;
    gap: 40px;
  }
}

.home-webdev {
  position: relative;
  margin-top: 100px;
}

.home-webdev p {
  font-size: 20px;
  width: 80%;
}
.home-webdev a {
  width: 60px;
  height: 60px;
  background: var(--primary-color);
  display: flex;
  color: var(--dark-color);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transition: .5s;
}

.home-webdev a:hover {
  background-color: transparent;
  color: #000;
  transform: scale(1.02);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 550px) {
  .home-webdev a {
    position: relative;
  }
  .home-webdev p {
    width: 100%;
    font-size: 18px;
  }
}
